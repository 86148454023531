<template>
  <div class="solution-container">
    <div class="banner-container">
      <div class="banner-box">
        <img src="../assets/res/title4.jpg" alt="" class="banner-img">
      </div>
    </div>

    <div class="container">
      <ul class="itme-box">
        <li v-for="(item,index) in solArr" :class="{select: curIndex === index}" :key="index" v-on:click="itemClick(index)" class="con-item">
          {{ item.title }}
        </li>
      </ul>
      <div class="content" v-if="curIndex == 0">
        <p>物联可信解决方案：</p>
        <p>
        由于软件安全性以及网络安全性，在最近二十年内相对已经得到显著加固，黑客的攻击路径开始沿着技术堆栈“下移”，反而越来越聚焦在硬件、芯片及其嵌入式软件（固件）作为攻击目标，硬件不像软件，可以被基本安全控件轻松地访问或扫描，尽管其攻击和分析需要依赖更专业的设备，被利用的难度更大，但同时，因为硬件的安全漏洞生命周期往往比较长，给攻击者提供了足够的时间窗口来利用或者施展各种攻击手段。
  我司在针对智能装备底层硬件，包括芯片、固件以及进行整机的攻击检测能力平台和加固解决方案，该解决方案具备较好的可一致性，典型应用包括智能家居、智能表计、智能门锁、车联网、金融、电力、交通、党政等行业。
        </p>
          
        <div class="map-box">
          <div class="box-title">
            <div class="title">
              <p><br></p>
              <p><br></p>
            </div>
          </div>
          <div class="content-box">
            <img src="../assets/res/物联可信解决方案.png" alt="" class="iotimage-box">
          </div>
          <div class="box-title">
            <div class="title">
              物联可信解决方案
              <p><br></p>
              <p><br></p>
            </div>
          </div>
        </div>                                    
      </div>

      <div class="content" v-if="curIndex == 1">
      
        <p>基于密码服务平台、国密U-Key、加密机、签名验签服务器、SSL VPN安全网关等商用密码软硬件产品 ，结合行业及应用特征，提供针对性的密码融合方案；采用国密算法，实现并满足网络和通信安全（TLS建链）、设备和计算安全、应用和数据安全所需的身份鉴别、安全通道、数据机密性、完整性、真实性和有效性等密评相关要求。</p>
          
        <div class="map-box">
          <div class="box-title">
            <div class="title">
              <p><br></p>
              <p><br></p>
            </div>
          </div>
          <div class="content-box">
            <img src="../assets/res/iot2.jpg" alt="" class="iotimage-box">
          </div>
          <div class="box-title">
            <div class="title">
              密码改造解决方案
              <p><br></p>
              <p><br></p>
            </div>
          </div>
        </div>
      </div>
      
      <div class="content" v-if="curIndex == 2">
      
        <p>基于密码服务平台、国密U-Key、加密机、签名验签服务器、SSL VPN安全网关等商用密码软硬件产品 ，结合行业及应用特征，提供针对性的密码融合方案；采用国密算法，实现并满足网络和通信安全（TLS建链）、设备和计算安全、应用和数据安全所需的身份鉴别、安全通道、数据机密性、完整性、真实性和有效性等密评相关要求。</p>
          
        <div class="map-box">
        <div class="box-title">
          <div class="title">
            <p><br></p>
            <p><br></p>
          </div>
        </div>
        <div class="content-box">
          <img src="../assets/res/iot2.jpg" alt="" class="iotimage-box">
        </div>
        <div class="box-title">
          <div class="title">
            密码改造解决方案
            <p><br></p>
            <p><br></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      curIndex: 0,
      solArr: [
        {
          title: "物联可信解决方案"
        },
        {
          title: "商用密码应用解决方案"
        },
        {
          title: "芯片安全实验室"
        },
        {
          title: "密改服务"
        },
      ]
    }
  },
  components: {

  },
  methods: {
    itemClick: function (index) {
      this.curIndex = index;
    }
  }
}
</script>

<style scoped lang="scss">
.solution-container{
    .banner-container {
    width: 1200px;
    margin: 0 auto;
    .banner-box {
      margin-top: 5px;
      .banner-img {
        width: 1200px;
        height: 256px;
      }
    }
  }

  .container {
    width: 1200px;
    margin: 0 auto;
    min-height: 600px;
    .itme-box {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
      border-bottom: 2px #ac0000 solid;
      .con-item {
        width: 240px;
        height: 36px;
        line-height: 36px;
      }
      .con-item:hover {
        margin-bottom: -2px;
        color: #ac0000;
        border-top: 2px #ac0000 solid;
        border-bottom: 2px #fff solid;
        height: 34px;
        line-height: 34px;
      }
      .select {
        margin-bottom: -2px;
        color: #ac0000;
        border-top: 2px #ac0000 solid;
        border-bottom: 2px #fff solid;
        height: 34px;
        line-height: 34px;
      }
    }
    .iotimage-box{
      width: 80%;
      height: 80%;
    }
    .content {
      margin: 20px 5px 0 0;
      p {
        font-size: 14px;
        line-height: 1.8;
        padding: 0;
        margin: 0;
        white-space: normal;
        word-wrap: break-word;
        word-break: normal;
        overflow: hidden;
        width: 100%;
        text-align: left;
      }
    }
  }
}
</style>
